// messages.js
import ruMessages from './locales/ru.json'
import plMessages from './locales/pl.json'

const messages = {
    ru: ruMessages,
    pl: plMessages
}

export default messages

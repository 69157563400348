import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import messages from './messages'
import App from './App'

// eslint-disable-next-line no-undef
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'ru'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
        <BrowserRouter>
            <App messages={messages[defaultLanguage]} locale={defaultLanguage} />
        </BrowserRouter>
    </IntlProvider>
)

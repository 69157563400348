import React from 'react'
import './index.css'
import { FormattedMessage } from 'react-intl'

const Header = () => {
    return (
        <div>
            <header className='header'>
                <div className='header-content'>
                    <div />
                    {/*<img src={'/assets/images/logo.png'} alt='Scanslated Logo' className='logo' />*/}
                    <nav className={`nav`}>
                        <a href='/app/example/'>
                            <button className='sample-report-button'>
                                <FormattedMessage id={'hero.button.try'} />
                            </button>
                        </a>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from './routes/HomePage'
import './App.css'
import { Helmet } from 'react-helmet'

function App({ messages, locale }) {
    return (
        <div>
            <Helmet>
                <meta name='description' content={messages['site-description']} />
                <title>{messages['site-title']}</title>
            </Helmet>
            <Routes>
                <Route path={'/'} Component={() => <HomePage locale={locale} />} />
            </Routes>
        </div>
    )
}

export default App
